import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

class AboutPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="En esto creo" keywords={[`javascript`, `react`]} />

        <h1>Cosas en las que creo</h1>
        <ul>
          <li>En la pasión por hacer cosas.</li>
          <li>En la gente que arde de Kerouac.</li>
          <li>En el absurdo de Camus.</li>
          <li>En Dostoyevsky.</li>
          <li>En la Historia.</li>
          <li>En la montaña y la playa.</li>
          <li>En que nadie nace aprendido.</li>
          <li>En la humildad.</li>
          <li>En la bicicleta por encima de todo para moverse en ciudad.</li>
          <li>En pensar en solucionar a largo plazo.</li>
          <li>En de dónde vengo.</li>
          <li>En que es mejor tomar una decisión aunque sea errónea a no decidir nada.</li>
          <li>En los equipos horizontales.</li>
        </ul>
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
